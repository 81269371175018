<template>
  <section>
    <v-row class="fill-height">
      <v-col>
        <v-btn
          color="primary"
          :to="`/site/${selectedSite.siteId}/virtual_monitor/create`"
          v-if="filteredMonitors.length == 0"
        >
          <v-icon left>mdi-plus-thick</v-icon>New Virtual Monitor
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          return-object
          @change="handleGetMonitors"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>apps</v-icon>
              </v-btn>
            </template>
            <span>Show Card View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>list_alt</v-icon>
              </v-btn>
            </template>
            <span>Show List View</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-if="noDevices">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800;"
      >
        No {{ typeName }} configured
      </v-card>
    </v-row>
    <confirm-dialog
      ref="confirmDelete"
      title="Delete Virtual Monitor?"
    ></confirm-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredMonitors"
      class="elevation-1"
      :options="defaultOptions"
      v-if="filteredMonitors.length > 0"
    >
      <template v-slot:[`item.hasRegisters`]="{ item }">
        <td>
          <v-icon v-if="item.hasRegisters === true" color="green darken-1"
            >check_circle_outline</v-icon
          >
          <v-icon color="red darken-1" v-else>block</v-icon>
        </td>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn
            :to="`/site/${item.siteId}/virtual_monitors/${item.monitorId}/edit`"
            @click="editItem(item)"
            icon
          >
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </section>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "../_api";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      typeName: "Virtual Monitors",
      titleIcon: "settings_system_daydream",
      sites: [],
      selectedSite: {},
      meters: [],
      selectedMeters: [],
      filteredMonitors: [],
      headers: [
        {
          text: "Name",
          value: "deviceName",
        },
        {
          text: "Display Name",
          value: "friendlyName", //need monitor name
        },
        {
          text: "Virtual Registers Configured",
          value: "hasRegisters",
        },
        { text: "Actions", value: "id", sortable: false },
      ],
      hasRegisters: false,
      defaultOptions: {
        // sortBy: ["version"],
        // sortDesc: true,
      },
    };
  },
  async created() {
    this.sites = await api.getSites();

    if (this.defaultSite !== null) {
      this.selectedSite = this.defaultSite;
      this.filteredMonitors = await api.getVirtualMonitorsForSite(
        this.selectedSite.siteId
      );
    }
  },
  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
    }),
    toggleView() {},
    editItem() {},

    async handleDelete(monitor) {
      const msg = `Are you sure you want to delete ${monitor.deviceName} and its associated data?  Deleting a Virtual Monitor cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      console.log(monitor);

      try {
        await api.deleteVirtualMonitor(monitor);
        this.filteredMonitors = await api.getVirtualMonitorsForSite(
          this.selectedSite.siteId
        );
      } catch (error) {
        console.error(error);
      }
    },

    async handleGetMonitors() {
      this.setDefaultSite(this.selectedSite);
      this.filteredMonitors = await api.getVirtualMonitorsForSite(
        this.selectedSite.siteId
      );
    },
  },
  computed: {
    ...mapGetters({
      defaultSite: "session/defaultSite",
    }),
    noDevices: function () {
      return this.filteredMonitors.length === 0;
    },
  },
};
</script>