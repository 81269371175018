const getSites = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/sites/portfolio')
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getVirtualMonitorsForSite = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/virtualmonitor/site/${siteId}/index`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getVirtualMonitorById = async (monitorId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/virtualmonitor/${monitorId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getMonitorsForSite = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/monitor/site/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getSiteLocationsForSite = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/sitelocation/SiteLocations/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getRegistersByMonitorId = async (monitorId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/register/monitor/${monitorId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getVirtualRegistersByMonitorId = async (monitorId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/virtualregister/monitor/${monitorId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getVirtualRegisterByRegisterId = async (registerId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/virtualregister/register/${registerId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getRegisterUnitOfMeasures = async (deviceSubTypeId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/registerunitofmeasure/${deviceSubTypeId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getCalculatedRegistersBySiteIdAndRegisterUnitOfMeasure = async (siteId, registerUnitOfMeasureId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/virtualregister/site/${siteId}/registerunitofmeasure/${registerUnitOfMeasureId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const createVirtualMonitor = async (monitor) => {
  try {
      await window.axios.post('/v1/virtualmonitor/create', monitor)
  } catch (err) {
      console.error(err);
      return Promise.reject(err)
  }
};

const createVirtualRegister = async (register) => {
  try {
      await window.axios.post('/v1/virtualregister/create', register)
  } catch (err) {
      console.error(err);
      return Promise.reject(err)
  }
};

const updateVirtualMonitor = async (monitor) => {
  try {
      await window.axios.put('/v1/virtualmonitor/update', monitor)
  } catch (err) {
      console.error(err);
      return Promise.reject(err)
  }
};

const deleteVirtualMonitor = async (meter) => {
  let result = null;
  var config = {
    data: meter
  }
  try {
    result = await window.axios.delete(`/v1/virtualmonitor/delete`, config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const deleteVirtualRegister = async (register) => {
  let result = null;
  var config = {
    data: {siteId: register.siteId, monitorId: register.monitorId, registerId: register.registerId}
  }
  try {
    result = await window.axios.delete(`/v1/virtualregister/delete`, config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getSites,
  getVirtualMonitorsForSite,
  getMonitorsForSite,
  getSiteLocationsForSite,
  getVirtualMonitorById,
  updateVirtualMonitor,
  createVirtualMonitor,
  createVirtualRegister,
  deleteVirtualMonitor,
  deleteVirtualRegister,
  getRegistersByMonitorId,
  getRegisterUnitOfMeasures,
  getCalculatedRegistersBySiteIdAndRegisterUnitOfMeasure,
  getVirtualRegistersByMonitorId,
  getVirtualRegisterByRegisterId,
}