var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[(_vm.filteredMonitors.length == 0)?_c('v-btn',{attrs:{"color":"primary","to":("/site/" + (_vm.selectedSite.siteId) + "/virtual_monitor/create")}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v("New Virtual Monitor ")],1):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto","md":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.sites,"item-text":function (item) { return item.name; },"label":"Select site","return-object":"","solo":""},on:{"change":_vm.handleGetMonitors},model:{value:(_vm.selectedSite),callback:function ($$v) {_vm.selectedSite=$$v},expression:"selectedSite"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{model:{value:(_vm.toggleView),callback:function ($$v) {_vm.toggleView=$$v},expression:"toggleView"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("apps")])],1)]}}])},[_c('span',[_vm._v("Show Card View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("list_alt")])],1)]}}])},[_c('span',[_vm._v("Show List View")])])],1)],1)],1),(_vm.noDevices)?_c('v-row',[_c('v-card',{staticClass:"card-center",staticStyle:{"background-color":"#ecf2f6 !important","font-weight":"800"},attrs:{"flat":"","tile":""}},[_vm._v(" No "+_vm._s(_vm.typeName)+" configured ")])],1):_vm._e(),_c('confirm-dialog',{ref:"confirmDelete",attrs:{"title":"Delete Virtual Monitor?"}}),(_vm.filteredMonitors.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredMonitors,"options":_vm.defaultOptions},scopedSlots:_vm._u([{key:"item.hasRegisters",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.hasRegisters === true)?_c('v-icon',{attrs:{"color":"green darken-1"}},[_vm._v("check_circle_outline")]):_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v("block")])],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"to":("/site/" + (item.siteId) + "/virtual_monitors/" + (item.monitorId) + "/edit"),"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }